<template>
  <div class="layout-config h-100 p-10">
    <Row type="flex" :gutter="10">
      <Col span="4">
        <Input v-model="param.query" placeholder="请输入全景图名称" clearable />
      </Col>
      <Col span="4">
        <Button type="primary" @click="getData()">查询</Button>
        <Button type="success" class="m-l-5" to="/config/dataManage/panorama/panoramaPage" v-if="isAuth">新增</Button>
      </Col>
    </Row>
    <Table border :columns="cols" :data="table.data && table.data.records" ref="table" class="m-t-10">
      <template slot-scope="{ row }" slot="qjtBtn">
        <Row type="flex" align="middle" :class="['m-t-5 m-b-5', row.qjtBtn.length - 1 == index ? '' : 'b-b-1']" v-for="(item, index) in row.qjtBtn" :key="index">
          <Col span="4" class="text-c b-r-1">{{ item.btnName }}</Col>
          <Col span="20" class="p-l-10 ellipsis w-100">
            <Tooltip :content="item.btnText" max-width="800" transfer class="w-100">
              <div class="ellipsis">{{ item.btnText }}</div>
            </Tooltip>
          </Col>
        </Row>
      </template>
    </Table>
    <Page :page="param.current" @on-change="getData" :page-size="10" :total="table.total" show-total class="text-r m-t-10" />
  </div>
</template>

<script>
import { getPanorama, deletePanorama } from '@/libs/api/panorama'
import { util } from '@/libs'
import { mapState } from 'vuex'
export default {
  data() {
    const _this = this
    return {
      isAuth: null,
      param: {
          current: 1,
          size: 10,
          order: 'name',
      },
      table: {},
      cols: [
        { title: "序号", type: "index", key: "index", width: 70, align: "center" },
        { title: "全景图名称", key: "name", width: 200, tooltip: true },
        // {
        //   title: "全景图链接", ellipsis: true, render(h, w) {
        //     if(w.row.type == true) {
        //       return h("span", w.row.urlList ? w.row.urlList.join(',') : '')
        //     } else {
        //       return h("span", w.row.url)
        //     }
        //   }
        // },
        { title: "全景图类型", key: "type", width: 110, render(h, w) { return h("span", w.row.type == true ? "立方体全景" : ( w.row.type == false ? '球形全景' : '') ) } },
        { title: "全景简介", slot: "qjtBtn" },
        {
          title: "操作", width: 200, render(h, w) {
            const btns = []
            if(w.row.url || (w.row.type && w.row.urlList)) {
              btns.push(h("Button", {
                props: { type: "info", size: "small" },
                on: {
                  click() {
                    _this.$router.push({
                      path: '/config/dataManage/panorama/panoramaView',
                      query: { id: w.row.id }
                    })
                  }
                }
              }, "预览"))
            }
            if(_this.isAuth) {
              btns.push(h("Button", {
                  props: { type: "success", size: "small" },
                  on: {
                    click() {
                      _this.$router.push({
                        path: '/config/dataManage/panorama/panoramaPage',
                        query: { id: w.row.id }
                      })
                    }
                  }
                }, "编辑"))
                btns.push(h("Button", {
                  props: { type: "error", size: "small" },
                  on: {
                    click() {
                      _this.$Modal.confirm({
                        title: "删除",
                        content: `是否删除当前${w.row.name}?`,
                        onOk: () => {
                          deletePanorama({ id: w.row.id }).then((res) => {
                            if(res.code == "HA0200") {
                              _this.$Message.success("删除成功！");
                              _this.getData();
                            }
                          });
                        }
                      });
                    }
                  }
                }, "删除"))
            }
            return btns
          }
        }
      ]
    }
  },
  computed: {
    ...mapState({
      userId: state => state.user.userId,
      roleCode: state => state.user.roleCode
    })
  },
  methods: {
    // 获取数据列表
    getData(page) {
      Object.assign(this.param, { current: page ? page : 1 })
      getPanorama(this.param).then(res => {
          res.code == "HA0200" ? this.table = res : this.$Message.error(res.msg || "查询错误")
      });
    }
  },
  mounted() {
    this.isAuth = this.roleCode.includes('admin') || this.roleCode.includes('mechanismAdmin') || this.roleCode.includes('teach') || this.roleCode.includes('yxpt-t')
    this.getData()
  }
}
</script>

<style lang="less" scoped></style>
